<template>
  <div>
    <vue-editor
      class='editFormFieldVueEditor'
      @focus='onEditorFocus'
      @ready='addSymbolsAndHelp(".editFormFieldVueEditor")'
      v-model='inputValue'
      :editor-options='editorOptions'>
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
export default {
  name: 'EditFormFieldVueEditorAbstract',
  props: {
    metaData: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
  },
  components: {
    VueEditor,
  },
  data () {
    return {
      inputValue: '',
      editorFocused: null,
      editorOptions:{
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
              [{symbol: [false, 'α', 'β', 'π']}], // custom symbol dropdown
              ['clean'],
            ],
            handlers: {
              symbol: (value) => {
                if (this.editorFocused && value) {
                  const cursorPosition = this.editorFocused.getSelection().index
                  this.editorFocused.insertText(cursorPosition, value)
                  this.editorFocused.setSelection(cursorPosition + value.length)
                }
              }
            }
          }
        }
      },
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.inputValue = newVal
        }
      }, 
      immediate: true,
    },
    inputValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:inputValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  },
  methods: {
    onEditorFocus (quill) {
      this.editorFocused = quill
    },
    addSymbolsAndHelp (nodeClass) {
      let symbolPickerItems = Array.prototype.slice.call(document.querySelectorAll(`${nodeClass} .ql-symbol .ql-picker-item`))
      symbolPickerItems.forEach(item => item.textContent = item.dataset.value)
      Array.prototype.slice.call(document.querySelectorAll(`${nodeClass} .ql-symbol .ql-picker-label`)).forEach((node) => node.innerHTML = '<span class="pr-3">Ω</span>' + node.innerHTML)

      Array.prototype.slice.call(document.querySelectorAll(`${nodeClass} .ql-tooltip`)).forEach((node) => node.innerHTML = node.innerHTML + '<a class="pl-3" target="_blank" href="https://katex.org/">help</a>')
    },
  },
}
</script>
